$body-bg: #fff;



@import "~bootstrap/scss/bootstrap";


html{ height:100%; }
body{ min-height:100%; padding:0; margin:0; position:relative; }

body::after{ content:''; display:block; height:100px; }

footer{
  position:absolute;
  bottom:0;
  width:100%;
  height:100px;
}
/* for desktop */
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
      margin-top: 10px;
  }

  .whatsapp_float {
      width: 40px;
      height: 40px;
      bottom: 20px;
      right: 10px;
      font-size: 22px;
  }

}

@media screen and (min-width: 35){
  table { font-size: calc( 2px + (6) * (100vw - 400px) / (1200 - 400) ); }
}

/* Safari <8 and IE <11 */
@media screen and (min-width: 15em){
  table { font-size: calc( 10px + (24 - 16) * (100vw - 400px) / (800 - 400) ); }
}
@media screen and (min-width: 750px){
  table { font-size: calc( 6px + (6) * (100vw - 400px) / (1200 - 400) ); }
}
@media screen and (min-width: 925px){
  table { font-size: calc( 8px + (6) * (100vw - 400px) / (1200 - 400) ); }
}

@media screen and (min-width: 65em){
  table { font-size: calc( 4px + (6) * (100vw - 400px) / (1200 - 400) ); }
}